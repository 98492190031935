import {TRepricerItem} from '@src/pages/repricer/types/repricer-item';

export const getMinMaxPrice = ({shops}: {shops: TRepricerItem['shops']}) => {
  if (!shops) return {price: '', minPrice: '', maxPrice: ''};

  const minPriceCurrent = Math.min(...shops.map((s) => s.price));
  const maxPriceCurrent = Math.max(...shops.map((s) => s.price));
  const minMinPrice = Math.min(...shops.map((s) => (s.minPrice === null ? 0 : s.minPrice)));
  const maxMinPrice = Math.max(...shops.map((s) => (s.minPrice === null ? 0 : s.minPrice)));
  const minMaxPrice = Math.min(...shops.map((s) => (s.maxPrice === null ? 0 : s.maxPrice)));
  const maxMaxPrice = Math.max(...shops.map((s) => (s.maxPrice === null ? 0 : s.maxPrice)));

  if (shops.length === 1) {
    return {
      price: shops[0].price,
      minPrice: shops[0].minPrice,
      maxPrice: shops[0].maxPrice,
    };
  } else {
    return {
      price: maxPriceCurrent === minPriceCurrent ? maxPriceCurrent : `${minPriceCurrent} - ${maxPriceCurrent}`,
      minPrice: minMinPrice === maxMinPrice ? minMinPrice : `${minMinPrice} - ${maxMinPrice}`,
      maxPrice: minMaxPrice === maxMaxPrice ? minMaxPrice : `${minMaxPrice} - ${maxMaxPrice}`,
    };
  }
};
