import {useNavigate} from 'react-router-dom';

import {COLORS} from '@shared/const/appPalette';
import {ChevronIcon} from '@src/components/common/icons';
import {useCurrentTariff} from '@src/hooks/useCurrenTariff';
import {getRoutePricing} from '@src/shared/const/router';
import {TARIFFS_TYPES} from '@src/shared/enums/tariffs';

import {Button, Notifications, Typography} from '../..';

import {useEmotionWrapper} from './limited-account.s';

export const LimitedAccount = () => {
  const {classes} = useEmotionWrapper();
  const navigate = useNavigate();
  const {currentTariff} = useCurrentTariff();

  const isViewLimitedNotify = () => {
    return (
      (currentTariff.hadPaidTariff && currentTariff.isTrial === false && currentTariff.isLimited) ||
      currentTariff.type === TARIFFS_TYPES.START
    );
  };

  if (!isViewLimitedNotify()) return null;

  return (
    <Notifications>
      <div className={classes.root}>
        <Typography weight="bold" color={COLORS.DARK_100} size={16}>
          Для продолжения работы, пожалуйста, оплатите тарифный план.
        </Typography>
        <div>
          <Button onClick={() => navigate(getRoutePricing())}>
            <div className={classes.button}>
              Выбрать тариф
              <ChevronIcon />
            </div>
          </Button>
        </div>
      </div>
    </Notifications>
  );
};
