export const BestPriceIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <mask id="mask0_660_8348" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={color} />
      </mask>
      <g mask="url(#mask0_660_8348)">
        <path
          d="M4.78782 3.90861L6.83859 1.23756C6.98507 1.04107 7.15902 0.896767 7.36044 0.804662C7.56185 0.712557 7.77242 0.666504 7.99215 0.666504C8.21187 0.666504 8.42244 0.712557 8.62386 0.804662C8.82527 0.896767 8.99922 1.04107 9.14571 1.23756L11.1965 3.90861L14.3093 4.95861C14.6266 5.05685 14.8769 5.238 15.06 5.50203C15.2431 5.76607 15.3346 6.05773 15.3346 6.37703C15.3346 6.5244 15.3133 6.67177 15.2705 6.81914C15.2278 6.9665 15.1576 7.10773 15.06 7.24282L13.0458 10.1165L13.1191 13.1376C13.1313 13.5674 12.9909 13.9297 12.6979 14.2244C12.405 14.5191 12.0632 14.6665 11.6725 14.6665C11.6481 14.6665 11.5139 14.6481 11.2697 14.6112L7.99215 13.6902L4.71458 14.6112C4.65354 14.6358 4.5864 14.6512 4.51316 14.6573C4.43992 14.6634 4.37278 14.6665 4.31175 14.6665C3.92112 14.6665 3.57933 14.5191 3.28636 14.2244C2.9934 13.9297 2.85302 13.5674 2.86522 13.1376L2.93846 10.0981L0.942625 7.24282C0.84497 7.10773 0.77478 6.9665 0.732055 6.81914C0.689331 6.67177 0.667969 6.5244 0.667969 6.37703C0.667969 6.07001 0.756469 5.78449 0.93347 5.52045C1.11047 5.25642 1.35766 5.06914 1.67504 4.95861L4.78782 3.90861ZM5.68503 5.17966L2.1328 6.35861L4.4033 9.65598L4.33006 13.1744L7.99215 12.1612L11.6542 13.1928L11.581 9.65598L13.8515 6.39545L10.2993 5.17966L7.99215 2.14019L5.68503 5.17966Z"
          fill={color}
        />
        <path
          d="M6.47143 11.3332V11.4332H6.57143H7.14286H7.24286V11.3332V10.544H8.28571H8.38571V10.444V9.85143V9.75143H8.28571H7.24286V9.02576H8.42857C8.89389 9.02576 9.29128 8.85665 9.61484 8.5211C9.93826 8.18571 10.1 7.77535 10.1 7.29613C10.1 6.81692 9.93826 6.40656 9.61484 6.07116C9.29128 5.73562 8.89389 5.5665 8.42857 5.5665H6.57143H6.47143V5.6665V8.23317H6H5.9V8.33317V8.92576V9.02576H6H6.47143V9.75143H6H5.9V9.85143V10.444V10.544H6H6.47143V11.3332ZM8.42857 8.23317H7.24286V6.3591H8.42857C8.67705 6.3591 8.88695 6.44888 9.06373 6.63221C9.24063 6.81567 9.32857 7.03508 9.32857 7.29613C9.32857 7.55719 9.24063 7.7766 9.06373 7.96005C8.88695 8.14338 8.67705 8.23317 8.42857 8.23317Z"
          fill={color}
          stroke={color}
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
};
