import {FC, useEffect, useMemo, useState} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {FormControl, InputAdornment, Menu, OutlinedInput, Typography} from '@mui/material';
import {useConfirmationDialog} from '@src/components';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';

import {useStrategyContext} from '../../../../hooks/useStrategyContext';
import {validateMaxPricePct} from '../../../../utils/valid-max-price-pct';

import {useEmotionWrapper} from './max-price-header-cell.s';

interface IMaxPriceHeaderCellProps {
  resetCollectPriceUpdateFunctions: (type: 'minPrice' | 'maxPrice' | 'price') => void;
}

export const MaxPriceHeaderCell: FC<IMaxPriceHeaderCellProps> = ({resetCollectPriceUpdateFunctions}) => {
  const {classes} = useEmotionWrapper();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {onChangeStrategy, strategy} = useStrategyContext();
  const open = Boolean(anchorEl);
  const [newMaxPercent, setNewMaxPercent] = useState<string | null>(null);
  const isNotValidNewMaxPercent = useMemo(() => validateMaxPricePct(newMaxPercent), [newMaxPercent]);
  const {getConfirmation} = useConfirmationDialog();

  useEffect(() => {
    setNewMaxPercent(strategy.maxPricePct ? strategy.maxPricePct.toString() : null);
  }, [strategy.id]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickApplyMaxPricePercent = async () => {
    handleClose();

    const confirm = await getConfirmation({
      title: 'Внимание',
      message: (
        <div>
          <Typography>Вы уверены, что хотите изменить минимальную цену?</Typography>
          <Typography>Минимальная цена у всех товаров будет обновлена.</Typography>
        </div>
      ),
    });

    if (confirm) {
      resetCollectPriceUpdateFunctions('maxPrice');
      onChangeStrategy({
        ...strategy,
        maxPricePct:
          stringToNumberWithDot(newMaxPercent || '') !== 0 ? stringToNumberWithDot(newMaxPercent || '') : null,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Typography sx={{fontWeight: 700, whiteSpace: 'nowrap'}}>Макс. цена</Typography>
      <MIconButton
        classes={{root: classes.buttonRoot}}
        sx={{backgroundColor: COLORS_REDESIGN.LIGHT_GREY}}
        onClick={handleClick}
      >
        %
      </MIconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <div style={{padding: 12}}>
          <MTypography sx={{fontWeight: 600}}>Макс. для всех товаров</MTypography>{' '}
          <MTypography sx={{fontWeight: 400, fontSize: 13, marginTop: 1}}>От 100 до 999%</MTypography>
          <div className={classes.inputRoot}>
            <FormControl error={isNotValidNewMaxPercent} size="small" sx={{width: 100}}>
              <OutlinedInput
                value={newMaxPercent}
                onChange={(event) => setNewMaxPercent(event.target.value)}
                placeholder="0"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <MButton disabled={isNotValidNewMaxPercent} onClick={onClickApplyMaxPricePercent} variant="contained">
              <CheckIcon />
            </MButton>
          </div>
        </div>
      </Menu>
    </div>
  );
};
