import {COLORS} from '@src/shared/const/appPalette';

import {emotionWrapper} from '../../../shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    border: 'none',
    outline: 'none',
    background: 'none',
    fontSize: 14,
    fontFamily: 'Inter',
    color: COLORS.BLUE_PRIMARY,
    transition: 'color .3s',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      transition: 'color .3s',
      color: COLORS.BLUE_SECONDARY,
      textDecoration: 'underline',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: COLORS.BLACK_50,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '&:not(:disabled)': {
      '&:active': {
        transform: 'scale(0.95)',
      },
    },
    '&:focus': {
      transition: 'color .3s',
      color: COLORS.BLUE_SECONDARY,
    },
  },
}));
