import {TRepricerItem} from '@src/pages/repricer/types/repricer-item';

export const adapterRepricerItem = (repricerItem: TRepricerItem) => {
  return {
    ...repricerItem,
    id: repricerItem.itemId,
    price: repricerItem.price / 100,
    marketProductId: repricerItem.marketProductId,
    marketSku: repricerItem.marketSku,
    attractivePrice: repricerItem.attractivePrice ? repricerItem.attractivePrice / 100 : null,
    recommendedPrice: repricerItem.recommendedPrice ? repricerItem.recommendedPrice / 100 : null,
    bestMarketOffer: {
      ...repricerItem.bestMarketOffer,
      price: repricerItem.bestMarketOffer.price ? repricerItem.bestMarketOffer.price / 100 : null,
    },
    currentPrice: repricerItem.currentPrice ? repricerItem.currentPrice / 100 : null,
    customerPrice: repricerItem.customerPrice ? repricerItem.customerPrice / 100 : null,
    moderateAttractivePrice: repricerItem.moderateAttractivePrice ? repricerItem.moderateAttractivePrice / 100 : null,
    anotherPlacePrice: {
      ...repricerItem.anotherPlacePrice,
      anotherMinPrice: repricerItem.anotherPlacePrice.anotherMinPrice
        ? repricerItem.anotherPlacePrice.anotherMinPrice / 100
        : null,
    },
    competitorsPrices:
      repricerItem.competitorsPrices &&
      repricerItem.competitorsPrices.map((cp) => ({
        ...cp,
        price: cp.price / 100,
        modelPrices: {
          minPrice: cp.modelPrices.minPrice ? cp.modelPrices.minPrice / 100 : null,
          maxPrice: cp.modelPrices.maxPrice ? cp.modelPrices.maxPrice / 100 : null,
          avgPrice: cp.modelPrices.avgPrice ? cp.modelPrices.avgPrice / 100 : null,
        },
      })),
    shops: repricerItem.shops.map((shop) => ({
      ...shop,
      minPrice: shop.minPrice ? shop.minPrice / 100 : null,
      maxPrice: shop.maxPrice ? shop.maxPrice / 100 : null,
      price: shop.price ? shop.price / 100 : null,
      isAuto:
        repricerItem.repricerShopItems.find((repricerShopItem) => shop.shopId === repricerShopItem.shopId)?.auto ||
        false,
    })),
    repricerShopItems: repricerItem.repricerShopItems.map((repricerShopItem) => ({
      ...repricerShopItem,
      minPrice: repricerShopItem.minPrice ? repricerShopItem.minPrice / 100 : null,
      maxPrice: repricerShopItem.maxPrice ? repricerShopItem.maxPrice / 100 : null,
    })),
  };
};
