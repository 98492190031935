import {FC} from 'react';

import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {TItemCostPriceHistory} from '@src/stores/market-place-account-item/types';
import dayjs from 'dayjs';

interface ICostPriceHistoryProps {
  history: TItemCostPriceHistory[];
}

export const CostPriceHistory: FC<ICostPriceHistoryProps> = ({history}) => {
  const lastUpdatedAt = history[0]?.updatedAt;
  const prevHistoryItem = history[history.length - 1];

  return (
    <MTypography fontSize={12}>
      {lastUpdatedAt && `Последнее обновление ${dayjs(lastUpdatedAt).format('DD.MM.YYYY HH:mm')}`}
      <br />
      {history.length > 1 &&
        `Пред. обновление ${dayjs(prevHistoryItem?.updatedAt).format('DD.MM.YYYY HH:mm')} (${prevHistoryItem?.costPrice} руб.) `}
      <br />
    </MTypography>
  );
};
