import {FC} from 'react';

import TableCell, {TableCellProps} from '@mui/material/TableCell/TableCell';

interface IMTableCell {
  children?: React.ReactNode;
}

type TMTableCellProps = IMTableCell & TableCellProps;

export const MTableCell: FC<TMTableCellProps> = ({children, ...props}) => {
  return <TableCell {...props}>{children}</TableCell>;
};
