export const DuplicateIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <mask id="mask0_610_8183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_610_8183)">
        <path
          d="M6.66602 15C6.20768 15 5.81532 14.8368 5.48893 14.5104C5.16254 14.184 4.99935 13.7917 4.99935 13.3334V3.33335C4.99935 2.87502 5.16254 2.48266 5.48893 2.15627C5.81532 1.82988 6.20768 1.66669 6.66602 1.66669H16.666C17.1243 1.66669 17.5167 1.82988 17.8431 2.15627C18.1695 2.48266 18.3327 2.87502 18.3327 3.33335V13.3334C18.3327 13.7917 18.1695 14.184 17.8431 14.5104C17.5167 14.8368 17.1243 15 16.666 15H6.66602ZM6.66602 13.3334H16.666V3.33335H6.66602V13.3334ZM3.33268 18.3334C2.87435 18.3334 2.48199 18.1702 2.1556 17.8438C1.82921 17.5174 1.66602 17.125 1.66602 16.6667V5.00002H3.33268V16.6667H14.9993V18.3334H3.33268Z"
          fill={color}
        />
        <path
          d="M8.39939 9.14976L10.8743 9.14976L10.8743 11.6246H12.5242L12.5242 9.14976L14.9991 9.14976L14.9991 7.49984H12.5242V5.02497L10.8743 5.02497V7.49984L8.39939 7.49984L8.39939 9.14976Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
