import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  tableRoot: {
    overflow: 'auto',
    padding: '0 16px',
    maxWidth: '1200px',
    maxHeight: 'calc(100vh - 250px)',
    '@media (max-width: 1000px)': {
      padding: '0 12px',
    },
  },
}));
