import {FC} from 'react';

import {ProductSettingsTable} from './repricer-item-table/product-settings-table/product-settings-table';
import {StrategySettings} from './strategy-settings/strategy-settings';

interface IStrategyDetailsProps {
  collectPriceUpdateFunctions: (type: 'minPrice' | 'maxPrice' | 'price', updateFunction: () => Promise<void>) => void;
  collectionPriceUpdateFunctions: {
    minPrice: Array<() => Promise<void>>;
    maxPrice: Array<() => Promise<void>>;
  };
  resetCollectPriceUpdateFunctions: (type: 'minPrice' | 'maxPrice' | 'price') => void;
}

export const StrategyDetails: FC<IStrategyDetailsProps> = ({
  collectPriceUpdateFunctions,
  collectionPriceUpdateFunctions,
  resetCollectPriceUpdateFunctions,
}) => {
  return (
    <div style={{display: 'flex', gap: 20, maxWidth: '100%', justifyContent: 'center'}}>
      <div style={{display: 'flex', gap: 20, width: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
        <ProductSettingsTable
          collectPriceUpdateFunctions={collectPriceUpdateFunctions}
          resetCollectPriceUpdateFunctions={resetCollectPriceUpdateFunctions}
        />
        <StrategySettings collectionPriceUpdateFunctions={collectionPriceUpdateFunctions} />
      </div>
    </div>
  );
};
