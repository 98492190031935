import {FC, useEffect, useState} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper/Paper';

import {MIconButton} from '../../mui-components/common/icon-button/icon-button';
import {MTypography} from '../../mui-components/common/typography/typography';

import {useEmotionWrapper} from './page-description.s';

interface IPageDescriptionProps {
  children: React.ReactNode;
  pageDescriptionId: string;
}

export const PageDescription: FC<IPageDescriptionProps> = ({children, pageDescriptionId, ...props}) => {
  const {classes} = useEmotionWrapper();
  const isViewed = localStorage.getItem(pageDescriptionId);
  const [isHide, setIsHide] = useState(true);

  useEffect(() => {
    if (isViewed === '1') {
      setIsHide(true);
    } else {
      setIsHide(false);
    }
  }, [isViewed]);

  if (isHide) return null;

  const onClose = () => {
    localStorage.setItem(pageDescriptionId, '1');
    setIsHide(true);
  };

  return (
    <Paper
      elevation={0}
      classes={{
        root: classes.root,
      }}
      {...props}
    >
      <div className={classes.content}>
        <MTypography sx={{fontSize: 14}}>{children}</MTypography>
        <MIconButton onClick={onClose} size="small">
          <CloseIcon />
        </MIconButton>
      </div>
    </Paper>
  );
};
