import {useContext} from 'react';

import {StrategyContext} from '../context/create-strategy';

export const useStrategyContext = () => {
  const {
    productsOnStrategy,
    onSelectProduct,
    strategy,
    onChangeStrategy,
    deleteProductIds,
    initialRepricerItems,
    isRepricerLimited,
  } = useContext(StrategyContext);

  return {
    productsOnStrategy,
    onSelectProduct,
    onChangeStrategy,
    strategy,
    deleteProductIds,
    initialRepricerItems,
    isRepricerLimited,
  };
};
