import {FC} from 'react';

import TableBody, {TableBodyProps} from '@mui/material/TableBody/TableBody';

interface IMTableBody {
  children?: React.ReactNode;
}

type TMTableBodyProps = IMTableBody & TableBodyProps;

export const MTableBody: FC<TMTableBodyProps> = ({children, ...props}) => {
  return <TableBody {...props}>{children}</TableBody>;
};
