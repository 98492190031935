import {FC} from 'react';

import IconButton, {IconButtonProps} from '@mui/material/IconButton';

interface IIconButtonProps {
  children?: React.ReactNode;
}

type TIconButtonProps = IIconButtonProps & IconButtonProps;

export const MIconButton: FC<TIconButtonProps> = ({children, ...props}) => {
  return <IconButton {...props}>{children}</IconButton>;
};
