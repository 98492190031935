import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  paperRoot: {
    padding: 20,
    width: 540,
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 60,
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  productName: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    width: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 700,
    fontSize: 18,
    color: COLORS_REDESIGN.DARK_NIGHT_BLUE,
  },
  blockTitle: {
    color: COLORS_REDESIGN.DARK_NIGHT_BLUE,
    fontWeight: 700,
    fontSize: 16,
  },
  priceContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 10,
  },
  detailsItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 10,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 20,
  },
  priceLogIframe: {
    width: '100%',
    border: 'none',
    height: 485,
  },
}));
