import {FC, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import {Box, CircularProgress, FormControl, FormHelperText, Input, InputAdornment} from '@mui/material';
import Menu from '@mui/material/Menu/Menu';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {useStore} from '@src/stores';
import {TRepricerItemCompetitor} from '@src/stores/repricer/types';

import {CompetitorItem} from './components/competitor-item/competitor-item';

type TAddNewCompetitor = {
  url: string;
  enabled: boolean;
};

interface ICompetitorsButton {
  isRepricerItem: boolean;
  competitors: TRepricerItemCompetitor[];
  itemId: number;
}

export const CompetitorsButton: FC<ICompetitorsButton> = observer(({isRepricerItem, itemId, competitors}) => {
  const {repricer} = useStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [newCompetitorUrl, setNewCompetitorUrl] = useState<string>('');
  const [competitorsList, setCompetitorsList] = useState<TRepricerItemCompetitor[]>([]);
  const [isLoadingAddNewCompetitor, setIsLoadingAddNewCompetitor] = useState<boolean>(false);
  const getCompetitors = async () => {
    try {
      const res = await repricer.getItemCompetitors(itemId);
      setCompetitorsList(res);
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    if (open) {
      getCompetitors();
    }

    return () => {
      setNewCompetitorUrl('');
    };
  }, [open]);

  useLayoutEffect(() => {
    if (itemId) {
      setCompetitorsList(competitors);
    }
  }, [itemId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickAddCompetitor = async (competitor: TAddNewCompetitor) => {
    try {
      setIsLoadingAddNewCompetitor(true);

      await repricer.addItemCompetitors({itemId, competitors: [competitor]});
      await getCompetitors();

      setNewCompetitorUrl('');
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingAddNewCompetitor(false);
    }
  };

  const onClickDeleteCompetitors = async (competitorId: number) => {
    try {
      await repricer.deleteItemCompetitors({itemId, competitorIds: [competitorId]});
      await getCompetitors();
    } catch (e) {
      console.log('e', e);
    }
  };

  const onChangeEnabled = async (competitor: TRepricerItemCompetitor) => {
    try {
      await repricer.updateItemCompetitors({itemId, competitors: [competitor]});
      await getCompetitors();
    } catch (e) {
      console.log('e', e);
    }
  };

  const isValidNewCompetitor = useMemo(() => {
    return competitorsList?.every((c) => c.url !== newCompetitorUrl);
  }, [competitorsList, newCompetitorUrl]);

  return (
    <>
      <MButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={!isRepricerItem}
        startIcon={competitorsList.length ? null : <AddCircleOutlineIcon />}
        variant="text"
      >
        {competitorsList.length ? `Конкуренты (${competitorsList.length})` : 'Отслеживать'}
      </MButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <div style={{display: 'flex', alignItems: 'flex-start', gap: 5}}>
          <FormControl error={!isValidNewCompetitor} variant="standard">
            <Input
              sx={{padding: '0 10px', minWidth: 350}}
              size="small"
              fullWidth
              onChange={(e) => setNewCompetitorUrl(e.target.value)}
              value={newCompetitorUrl}
              placeholder="Вставьте ссылку на конкурента"
              endAdornment={<InputAdornment sx={{paddingBottom: 1}} position="end"></InputAdornment>}
            />
            <FormHelperText>{isValidNewCompetitor ? '' : 'Указанная ссылка уже добавлена'}</FormHelperText>
          </FormControl>
          {
            <MButton
              sx={{marginBottom: 1, width: 24, height: 32}}
              onClick={() => onClickAddCompetitor({url: newCompetitorUrl, enabled: true})}
              disabled={!isValidNewCompetitor || !newCompetitorUrl}
              size="small"
              variant="contained"
            >
              {isLoadingAddNewCompetitor ? <CircularProgress sx={{color: COLORS.WHITE}} size={20} /> : <CheckIcon />}
            </MButton>
          }
        </div>

        {competitorsList.length > 0 ? (
          competitorsList?.map((competitor, idx) => (
            <CompetitorItem
              key={competitor.id}
              idx={idx}
              competitor={competitor}
              onClickDeleteCompetitors={onClickDeleteCompetitors}
              onChangeEnabled={onChangeEnabled}
            />
          ))
        ) : (
          <Box sx={{display: 'flex', justifyContent: 'center', padding: '5px 0'}}>
            <MTypography sx={{color: COLORS_REDESIGN.DARK_GREY}}>Ссылки конкурентов отсутствуют</MTypography>
          </Box>
        )}
      </Menu>
    </>
  );
});
