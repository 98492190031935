import {flow, Instance, types} from 'mobx-state-tree';
import api from '../../shared/configs/axiosConfig';
import axios, {AxiosResponse} from 'axios';

import {handlingConsoleRequestError} from '@src/shared/utils/handlingConsoleRequestError';

import {USER_AUTH_ACCESS_TOKEN, USER_AUTH_REFRESH_TOKEN} from '../../shared/const/localStorage';
import {getJWTToken} from '../../shared/utils/getJWTToken';

export type CurrentUserStoreModel = Instance<typeof CurrentUserStore>;
export type TUserStoreModel = Instance<typeof UserStore>;

type TGetTokenResponse = {
  access_token: string;
  refresh_token: string;
};

type TGetUserInfo = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

export const CurrentUserStore = types.model('CurrentUserStore', {
  id: types.number,
  email: types.string,
  firstName: types.string,
  lastName: types.string,
  createdAt: types.string,
});

export const UserStore = types
  .model('UserStoreModel', {
    data: types.optional(CurrentUserStore, {
      id: 0,
      email: '',
      firstName: '',
      lastName: '',
      createdAt: '',
    }),
    isReferral: types.optional(types.boolean, false),
    isAuth: types.optional(types.maybe(types.boolean), false),
    isNewUser: types.optional(types.maybe(types.boolean), false),
    _init: types.optional(types.maybe(types.boolean), undefined),
  })
  .actions((self) => ({
    getToken: flow(function* ({
      token,
      expiresIn,
      referralId,
    }: {
      token: string;
      expiresIn: number;
      referralId: string | null;
    }) {
      try {
        const {access_token, refresh_token, is_new_user} = yield axios
          .post(`${import.meta.env.WEB_API_BASE_URL}/auth/yandex`, {
            token,
            expiresIn,
            referralId,
          })
          .then((r: AxiosResponse<TGetTokenResponse>) => r.data);
        self.isNewUser = is_new_user;
        localStorage.setItem(USER_AUTH_ACCESS_TOKEN, access_token);
        localStorage.setItem(USER_AUTH_REFRESH_TOKEN, refresh_token);
        return access_token;
      } catch (e) {
        console.log(e);
      }
    }),
    refreshToken: flow(function* () {
      try {
        const access_token = yield axios
          .post(`${import.meta.env.WEB_API_BASE_URL}/auth/refresh`, null, {
            headers: {
              Authorization: `Bearer ${getJWTToken().refreshToken}`,
            },
          })
          .then((r: AxiosResponse<{access_token: string}>) => r.data.access_token);
        localStorage.setItem(USER_AUTH_ACCESS_TOKEN, access_token);
      } catch (e) {
        console.log('e', e);
        throw e;
      }
    }),
    getUserInfo: flow(function* () {
      try {
        if (getJWTToken().accessToken) {
          const {data} = yield api.get('/user/info').then((r: AxiosResponse<TGetUserInfo>) => r);

          self.data = {
            id: data.id,
            email: data?.email,
            firstName: data?.first_name || '',
            lastName: data?.last_name || '',
            createdAt: data?.created_at || '',
          };
          self.isReferral = data.is_referral;
        } else {
          self.isAuth = false;
          throw new Error('Не авторизован');
        }

        self.isAuth = true;
      } catch (e) {
        self.isAuth = false;
        throw e;
      }
    }),
    closeWelcomeMessageModal: function () {
      self.isNewUser = false;
    },
    addUserContacts: flow(function* (userContacts: {name: string; phone: string; telegramUsername?: string}) {
      try {
        yield api.put(`${import.meta.env.WEB_API_BASE_URL}/user/contacts`, userContacts);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    logout: () => {
      localStorage.removeItem(USER_AUTH_ACCESS_TOKEN);
      localStorage.removeItem(USER_AUTH_REFRESH_TOKEN);
      self.isAuth = false;
      self.isNewUser = false;
      self.data = {
        id: 0,
        email: '',
        firstName: '',
        lastName: '',
        createdAt: '',
      };
    },
    setIsInitApp: (state: boolean) => {
      self._init = state;
    },
  }));
