import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';

export const validateMaxPricePct = (percent: string | number | null) => {
  const maxPct = stringToNumberWithDot(percent || 0);

  if ((maxPct > 999 || maxPct < 100) && percent !== null && percent !== '') {
    return true;
  }

  return false;
};
