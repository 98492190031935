import {type FC} from 'react';

import Table, {TableProps} from '@mui/material/Table/Table';

interface IMTable {
  children?: React.ReactNode;
}

type TMTableProps = IMTable & TableProps;

export const MTable: FC<TMTableProps> = ({children, ...props}) => {
  return <Table {...props}>{children}</Table>;
};
