import {useStore} from '@src/stores';

export const useTariff = (tariffId?: number) => {
  const {tariffs} = useStore();

  const tariff = tariffId ? tariffs.list.find((t) => t.id === tariffId) : null;

  return {
    tariff: tariff,
  };
};
