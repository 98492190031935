import {parseJwt} from '@src/shared/utils/parse-jwt';

export const getRemainingTokenTime = (token: string | null) => {
  if (!token) {
    return 0;
  }

  const payload = parseJwt(token);
  const expirationTime = payload.exp * 1000;
  const currentTime = Date.now();
  const remainingTime = expirationTime - currentTime;
  const remainingMinutes = Math.floor(remainingTime / 60000);

  return remainingMinutes;
};
