import {useCallback, useLayoutEffect, useState} from 'react';

interface IUseInfiniteLoader {
  refEl: React.RefObject<HTMLDivElement>;
  loadMore: () => Promise<void>;
  offset: number;
}

export const useInfiniteLoader = (props: IUseInfiniteLoader) => {
  const {refEl, loadMore, offset} = props;

  const [distanceBottom, setDistanceBottom] = useState(0);
  const [hasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const onLoad = async () => {
    setLoading(true);
    await loadMore();
    setLoading(false);
  };

  const scrollListener = useCallback(() => {
    if (refEl.current !== null) {
      const bottom = refEl.current.scrollHeight - refEl.current.clientHeight;
      if (!distanceBottom) {
        setDistanceBottom(Math.round(bottom * 0.2));
      }
      if (refEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
        onLoad();
      }
    }
  }, [hasMore, loadMore, loading, distanceBottom, offset]);

  useLayoutEffect(() => {
    const refElementCurrent = refEl.current;
    refElementCurrent?.addEventListener('scroll', scrollListener);
    return () => {
      refElementCurrent?.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  return {
    loading,
  };
};
