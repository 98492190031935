import React, {FC} from 'react';

import Tippy, {TippyProps} from '@tippyjs/react';
import {followCursor} from 'tippy.js';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import './tooltip.css';

const POSITIONS = [
  'top',
  'bottom',
  'left',
  'right',
  'auto',
  'top-start',
  'top-end',
  'bottom-start',
  'bottom-end',
  'left-start',
  'left-end',
  'right-start',
  'right-end',
  'auto-start',
  'auto-end',
] as const;

export type PositionType = (typeof POSITIONS)[number];

interface ITooltip extends Omit<TippyProps, 'content'> {
  content: React.ReactNode | string;
  destroy?: boolean;
  isClickable?: boolean;
  width?: number | string;
  position?: PositionType;
  interactive?: boolean;
  enableTabIndex?: boolean;
}

export const Tooltip: FC<ITooltip> = ({
  children,
  content,
  destroy = false,
  isClickable = false,
  interactive = false,
  position = 'bottom',
  enableTabIndex = true,
  ...others
}) => {
  return (
    <Tippy
      plugins={[followCursor]}
      inertia
      interactive={interactive}
      theme="light"
      hideOnClick
      maxWidth={750}
      animation="shift-away"
      appendTo={document.body}
      delay={isClickable ? 0 : 100}
      trigger={isClickable ? 'click' : 'mouseenter'}
      placement={position}
      disabled={destroy}
      content={content}
      {...others}
    >
      {enableTabIndex ? <span tabIndex={0}>{children}</span> : children}
    </Tippy>
  );
};
