import {getJWTToken} from '@src/shared/utils/getJWTToken';
import {getRemainingTokenTime} from './get-remaining-token-time';

export const tokenExpirationTimer = (onRefreshToken: () => Promise<void>) => {
  setInterval(() => {
    const expAccessToken = getRemainingTokenTime(getJWTToken().accessToken);
    if (expAccessToken <= 2) {
      onRefreshToken();
    }
  }, 2000);
};
