import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  buttonRoot: {
    borderRadius: 8,
    padding: 5,
    width: 32,
    height: 32,
    fontSize: 16,
    fontWeight: 700,
    color: 'white',
    backgroundColor: COLORS_REDESIGN.BRAND,
    '&:hover': {
      backgroundColor: COLORS_REDESIGN.BRAND,
    },
  },
  inputRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 10,
  },
}));
