import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
  },
}));
