import {Typography} from '@src/components/common/typography';

import {useEmotionWrapper} from './app-loader.s';

export const AppLoader = () => {
  const {classes} = useEmotionWrapper();
  return (
    <div className={classes.root}>
      <img width={120} src="/svg/smart-seller-logo-animated.svg" />
      <Typography size={28}>Smart Seller</Typography>
    </div>
  );
};
