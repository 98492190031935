import React, {type FC} from 'react';
import {observer} from 'mobx-react-lite';

import {NotificationsManager} from '@src/components/specific/notifications-manager/notifications-manager';

import {Header} from '../header';
import {Sidebar} from '../sidebar';

import {useEmotionWrapper} from './app-container.s';

interface IAppContainer {
  children: React.ReactNode;
}

export const AppContainer: FC<IAppContainer> = observer(({children}) => {
  const {classes} = useEmotionWrapper();

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.appContainer}>
        <NotificationsManager />
        <div className={classes.contentContainer}>
          <Header />
          <div id="content" className={classes.contentBlock}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});
