import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Loader} from '@src/components';
import {REFERRAL_ID} from '@src/shared/const/localStorage';

export const PartnerPage = () => {
  const navigate = useNavigate();

  const getReferralId = () => {
    const url = new URL(window.location.href);

    return url.pathname.split('/').pop();
  };

  useEffect(() => {
    const referralId = getReferralId();

    if (referralId) {
      sessionStorage.setItem(REFERRAL_ID, referralId);
    }
    navigate('/');
  }, []);

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 100px)'}}>
      <Loader size="62px" />
    </div>
  );
};
