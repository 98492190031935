import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  rootStepper: {
    width: 800,
    position: 'relative',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  prevStep: {
    position: 'absolute',
    left: -10,
    top: -8,
  },
  nextStep: {
    position: 'absolute',
    right: -10,
    top: -8,
  },
}));
