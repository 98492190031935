import {FC} from 'react';

interface ITab {
  title: string;
  children: JSX.Element | string | null;
  path: string;
  isHide?: boolean;
}

export const Tab: FC<ITab> = ({children, isHide = false}) => {
  if (isHide) return null;
  return children;
};
