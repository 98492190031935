import {FC} from 'react';
import {observer} from 'mobx-react-lite';

import {MTable} from '@src/components/redesign/mui-components/common/table/table';
import {MTableBody} from '@src/components/redesign/mui-components/common/table/table-body';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableContainer} from '@src/components/redesign/mui-components/common/table/table-container';
import {MTableHead} from '@src/components/redesign/mui-components/common/table/table-head';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {TRepricerStrategy} from '@src/stores/repricer/types';

import {EmptyStrategies} from '../empty-strategies/empty-strategies';
import {StrategiesTableLoader} from '../strategies-table-loader/strategies-table-loader';
import {StrategyRow} from '../strategy-row/strategy-row';

import {useEmotionWrapper} from './strategies-table.s';

interface IStrategiesTable {
  strategies: TRepricerStrategy[];
  isLoadingStrategies: boolean;
}

export const StrategiesTable: FC<IStrategiesTable> = observer(({strategies, isLoadingStrategies}) => {
  const {classes} = useEmotionWrapper();

  return (
    <MTableContainer className={classes.root}>
      <MTable>
        <MTableHead
          classes={{
            root: classes.tableHeader,
          }}
        >
          <MTableRow className={classes.tableHeaderRow}>
            <MTableCell>Название стратегии</MTableCell>
            <MTableCell>Стратегия</MTableCell>
            <MTableCell align="right">Действия</MTableCell>
          </MTableRow>
        </MTableHead>
        <MTableBody className={classes.tableBody}>
          {isLoadingStrategies ? (
            <StrategiesTableLoader />
          ) : strategies.length ? (
            strategies.map((strategy) => <StrategyRow key={strategy.id} strategy={strategy} />)
          ) : (
            <EmptyStrategies />
          )}
        </MTableBody>
      </MTable>
    </MTableContainer>
  );
});
