import {useStore} from '@src/stores';

export const useAccountLimits = () => {
  const {mpAccounts, tariffs} = useStore();

  const currentTariff = tariffs.list.find((item) => item.id === mpAccounts.current?.tariff?.tariffId);
  if (!currentTariff || !mpAccounts.current) {
    return {
      repricerItemLimits: 0,
      maxBidderItems: 0,
    };
  }

  return {
    maxRepricerItems: mpAccounts.current?.limits?.maxRepricerItems
      ? mpAccounts.current.limits.maxRepricerItems
      : currentTariff.maxRepricerItems,
    maxBidderItems: mpAccounts.current?.limits?.maxBidderItems
      ? mpAccounts.current.limits.maxBidderItems
      : currentTariff.maxBidderItems,
  };
};
