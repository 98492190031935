import {FC} from 'react';

import {COLORS} from '@shared/const/appPalette';
import {CheckedIcon} from '@src/components/common/icons';

import {useEmotionWrapper} from './checkbox.s';

interface ICheckbox {
  label?: string;
  onChange?: () => void;
  checked: boolean;
  labelSize?: string | number;
}

export const Checkbox: FC<ICheckbox> = ({label, checked, onChange, labelSize = 12, ...props}) => {
  const {classes} = useEmotionWrapper({checked});
  return (
    <label
      tabIndex={0}
      className={classes.root}
      onChange={(e) => {
        e.stopPropagation();
        onChange && onChange();
      }}
      {...props}
    >
      <div className={classes.checkIconBlock}>{checked && <CheckedIcon size="12px" color={COLORS.DARK_100} />}</div>
      <input className={classes.input} type="checkbox" />
      {label && (
        <span style={{cursor: 'pointer', fontSize: labelSize}} color={COLORS.DARK_100}>
          {label}
        </span>
      )}
    </label>
  );
};
