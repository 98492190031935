import {useEffect} from 'react';

export const useHideScrollbar = (trigger: boolean) => {
  const body: HTMLElement = document.body;

  useEffect(() => {
    if (trigger) {
      body.style.overflow = 'hidden';
      body.style.touchAction = 'none';
    } else {
      body.style.overflow = 'auto';
      body.style.touchAction = 'auto';
    }

    return () => {
      body.style.overflow = 'auto';
      body.style.touchAction = 'auto';
    };
  }, [trigger]);
};
