import {COLORS} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

interface ISideBarStyle {
  isCompact: boolean;
}

export const useEmotionWrapper = emotionWrapper(({isCompact}: ISideBarStyle) => ({
  root: {
    height: '100vh',
    backgroundColor: COLORS.WHITE,
    position: 'sticky', //sticky
    boxShadow: '1px 0px 16px 1px rgba(240, 240, 240, 1);',
    top: 0,
    zIndex: 1,
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  menuIcon: {
    top: 150,
    position: 'absolute',
  },
  isCompactSide: {
    width: isCompact ? 70 : 190,
    transition: 'width .3s ease-in-out',
    div: {
      transform: isCompact ? 'rotate(0deg)' : 'rotate(180deg)',
      right: isCompact ? '20%' : -20,
      transition: `transform .3s ease-in-out, right .3s ease-in-out;`,
      position: 'absolute',
      zIndex: 1,
    },
  },
  smartSellerIcon: {
    position: 'absolute',
    right: '50%',
    transform: 'translateX(50%)',
    width: isCompact ? 40 : 70,
    transition: 'width .3s ease-in-out, right .3s ease-in-out',
    cursor: 'pointer',
  },
  menuBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 230,
  },
  oferta: {
    position: 'absolute',
    bottom: 3,
    left: 10,
    cursor: 'pointer',
    span: {
      '&:hover': {
        color: COLORS.PURPLE_100,
      },
    },
  },
}));
