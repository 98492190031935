import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {display: 'flex'},
  contentContainer: {
    padding: '0px 0 12px 42px',
    position: 'relative',
    flexGrow: 1,
  },
  contentBlock: {
    overflow: 'auto',
    paddingTop: 18,
    paddingBottom: 5,
    paddingLeft: 1,
    scrollbarGutter: 'stable',
    paddingRight: 34,
    '@media (max-width: 1440px)': {
      paddingRight: 15,
    },
  },
  appContainer: {
    width: '100%',
  },
}));
