import {FC} from 'react';

import Button, {ButtonProps} from '@mui/material/Button/Button';

interface IButtonProps {
  children?: React.ReactNode;
}

type TButtonProps = IButtonProps & ButtonProps;

export const MButton: FC<TButtonProps> = ({children, ...props}) => {
  return (
    <Button sx={{textTransform: 'none', fontSize: 15, fontWeight: 500, borderRadius: '8px'}} {...props}>
      {children}
    </Button>
  );
};
