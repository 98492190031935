import {emotionWrapper} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 80,
    width: '100%',
    paddingRight: 15,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    justifyContent: 'flex-start',
    minWidth: 150,
    maxWidth: 1100,
    position: 'relative',
    ':before': {
      position: 'absolute',
      left: -25,
      content: '""',
      display: 'block',
      width: 3,
      height: '100%',
      borderRadius: 10,
      background: COLORS.PURPLE_100,
    },
  },
  label: {
    borderRadius: 4,
    color: COLORS.BLACK_100,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 18,
    justifyContent: 'center',
    fontSize: 9,
    fontWeight: 600,
  },
  labelContainer: {
    width: 130,
    paddingRight: 25,
  },
  logItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  logContent: {
    width: '100%',
    textAlign: 'justify',
    fontSize: 13,
    fontWeight: 500,
    color: COLORS.DARK_100,
    lineHeight: 1.45,
  },
}));
