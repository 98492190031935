import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';

export const validateMinPricePct = (percent: string | number | null) => {
  const minPct = stringToNumberWithDot(percent || 0);

  if (minPct > 100) {
    return true;
  }

  return false;
};
