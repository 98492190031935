import {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {Typography} from '@mui/material';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {SlideUpModal} from '@src/components/redesign/slide-up-modal/slide-up-modal';
import {useAccountLimits} from '@src/hooks/useAccountLimits';
import {getRoutePricing, getRouteRepricer} from '@src/shared/const/router';
import {REPRICER_STRATEGY} from '@src/shared/enums/repricer-strategy';
import {declOfNum} from '@src/shared/utils/declOfNum';
import {useStore} from '@src/stores';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';
import {TRepricerStrategy} from '@src/stores/repricer/types';

import {StrategyContext} from './context/create-strategy';
import {InfiniteLoadContext} from './context/infinite-load';
import {EditStrategyStepper} from './edit-strategy-stepper/edit-strategy-stepper';
import {AddProducts} from './steps/add-items/add-items';
import {StrategyDetails} from './steps/strategy-details/strategy-details';
import {adapterRepricerItem} from './utils/adapter-repricer-item';
import {NEW_STRATEGY_ID, REPRICER_SEARCH_ITEMS_OFFSET} from '../../consts';
import {TRepricerItem} from '../../types/repricer-item';

const steps = ['Добавьте товары в стратегию', 'Детали стратегии'];

export const EditStrategyModal = observer(() => {
  const {strategyId} = useParams();
  const {repricer, mpAccounts} = useStore();
  const navigate = useNavigate();
  const {maxRepricerItems} = useAccountLimits();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingRepricerItems, setIsLoadingRepricerItems] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  const [productsOnStrategy, setProductsOnStrategy] = useState<TMpAccountItem[]>([]); //товары учавствующие в стратегии + кандидаты
  const [strategyData, setStrategyData] = useState<TRepricerStrategy>({} as TRepricerStrategy); //обновленная информация стратегии
  const [deleteProductIds, setDeleteProductIds] = useState<number[]>([]); //id удаляемых товаров

  const [initialRepricerItems, setInitialRepricerItems] = useState<TRepricerItem[]>({} as TRepricerItem[]); // товары учавствуещие в стратегии на момент получения данных из бд

  const [pageParams, setPageParams] = useState({offset: 0, limit: REPRICER_SEARCH_ITEMS_OFFSET, count: 0});

  const [collectionPriceUpdateFunctions, setCollectionPriceUpdateFunctions] = useState<{
    price: Array<() => Promise<void>>;
    minPrice: Array<() => Promise<void>>;
    maxPrice: Array<() => Promise<void>>;
  }>({
    price: [],
    minPrice: [],
    maxPrice: [],
  });

  const isRepricerLimited = useMemo(
    () => (maxRepricerItems !== null && maxRepricerItems ? productsOnStrategy.length >= maxRepricerItems : false),
    [maxRepricerItems, productsOnStrategy.length]
  );

  const findStrategyById = useMemo(() => {
    return repricer.list.find((strategy) => strategy.id === Number(strategyId));
  }, [JSON.stringify(repricer.list), strategyId]);

  const collectPriceUpdateFunctions = (
    type: 'minPrice' | 'maxPrice' | 'price',
    updateFunction: () => Promise<void>
  ) => {
    setCollectionPriceUpdateFunctions((prev) => ({
      ...prev,
      [type]: [...prev[type], updateFunction],
    }));
  };

  const resetCollectPriceUpdateFunctions = (type: 'minPrice' | 'maxPrice' | 'price') => {
    setCollectionPriceUpdateFunctions((prev) => ({
      ...prev,
      [type]: [],
    }));
  };

  const loadRepricerItems = async (offset: number) => {
    setIsLoadingRepricerItems(true);
    const marketPlaceAccountId = mpAccounts.current?.id;

    if (strategyId && marketPlaceAccountId) {
      repricer
        .getStrategyItems({strategyId: Number(strategyId), marketPlaceAccountId, pageParams: {...pageParams, offset}})
        .then((res) => {
          const mappedRepricerItem = res.items.map((i: TRepricerItem) => adapterRepricerItem(i)); //маппим модель TRepricerItem и TMpAccountItem (товар). У TRepricerItem добавляем поле id равное itemId

          setPageParams({offset: res.offset, limit: res.limit, count: res.count});
          setProductsOnStrategy([...productsOnStrategy, ...mappedRepricerItem]);
          setInitialRepricerItems(mappedRepricerItem);
        })
        .catch((e) => {
          console.log('e', e);
        })
        .finally(() => {
          setIsLoadingRepricerItems(false);
        });
    }
  };

  const loadMoreRepricerItems = async (offset: number) => {
    if (offset < pageParams.count) {
      const marketPlaceAccountId = mpAccounts.current?.id;
      setPageParams({...pageParams, offset: offset});
      if (strategyId && marketPlaceAccountId) {
        try {
          const res = await repricer.getStrategyItems({
            strategyId: Number(strategyId),
            marketPlaceAccountId,
            pageParams: {offset, limit: pageParams.limit},
          });
          const mappedRepricerItem = res.items.map((i: TRepricerItem) => adapterRepricerItem(i));

          setProductsOnStrategy([...productsOnStrategy, ...mappedRepricerItem]);
          setInitialRepricerItems(mappedRepricerItem);
        } catch (e) {
          console.log('e', e);
        }
      }
    }
  };
  useEffect(() => {
    setStrategyData({
      id: strategyId ? Number(strategyId) : NEW_STRATEGY_ID,
      name: findStrategyById?.name || '',
      type: findStrategyById?.type || REPRICER_STRATEGY.DEFAULT,
      maxPricePct: findStrategyById?.maxPricePct === undefined ? null : findStrategyById?.maxPricePct,
      minPricePct: findStrategyById?.minPricePct === undefined ? null : findStrategyById?.minPricePct,
      enabled: findStrategyById?.enabled !== undefined ? findStrategyById?.enabled : true,
      isAuto: findStrategyById?.isAuto || false,
      params: findStrategyById?.params || {place: null},
      competitors: (findStrategyById?.competitors as TRepricerStrategy['competitors']) || [],
    });
  }, [JSON.stringify(repricer.list), strategyId]);

  useEffect(() => {
    loadRepricerItems(0);
  }, [strategyId, mpAccounts.current?.id]);

  useEffect(() => {
    if (strategyId) {
      const findStrategy = repricer.list.find((item) => item.id === Number(strategyId));

      if (findStrategy) {
        setCurrentStep(1);
      } else {
        navigate(getRouteRepricer());
      }
    }
    setIsOpen(true);

    return () => {
      setIsOpen(false);
    };
  }, [strategyId]);

  //при изменении максимального процента цены апдейтим на интерфейсе минимальные цены во всех магазинах
  useEffect(() => {
    let newProductOnStrategy = productsOnStrategy.map((product) => {
      return {
        ...product,
        shops: product.shops.map((s) => {
          return {
            ...s,
            minPrice:
              strategyData.minPricePct === null
                ? s.minPrice
                : ((s.price ? s.price : product.price) * (strategyData.minPricePct || 100)) / 100,
          };
        }),
      };
    });

    setProductsOnStrategy(newProductOnStrategy as TMpAccountItem[]);
    newProductOnStrategy = [];
  }, [strategyData.minPricePct]);

  //при изменении максимального процента цены апдейтим на интерфейсе максимальные цены во всех магазинах
  useEffect(() => {
    let newProductOnStrategy = productsOnStrategy.map((product) => {
      return {
        ...product,
        shops: product.shops.map((s) => {
          return {
            ...s,
            maxPrice:
              strategyData.maxPricePct === s.maxPrice
                ? null
                : ((s.price ? s.price : product.price) * (strategyData.maxPricePct || 100)) / 100,
          };
        }),
      };
    });

    setProductsOnStrategy(newProductOnStrategy as TMpAccountItem[]);
    newProductOnStrategy = [];
  }, [strategyData.maxPricePct]);

  const onSelectProduct = (product: TMpAccountItem) => {
    const findProductIndex = productsOnStrategy.findIndex((p) => p.id === product.id);

    if (findProductIndex === -1) {
      setProductsOnStrategy([...productsOnStrategy, product]);
    } else {
      // если товар уже есть в стратегии, либо был добавлен, как кандидат, то удаляем его
      setProductsOnStrategy([
        ...productsOnStrategy.slice(0, findProductIndex),
        ...productsOnStrategy.slice(findProductIndex + 1),
      ]);

      const findInitialProductIndex = initialRepricerItems.findIndex((p) => p.itemId === product.id);
      // если товар уже учавствовал в стратегии НЕ в качестве кандидата, то добавляем его в массив для удаления товаров из стратегии
      if (findInitialProductIndex !== -1) {
        setDeleteProductIds([...deleteProductIds, initialRepricerItems[findInitialProductIndex].itemId]);
      }
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setTimeout(() => navigate(getRouteRepricer()), 300);
  };

  useEffect(() => {
    if (isRepricerLimited) {
      toast.info(
        <div>
          <Typography sx={{fontSize: 16, fontWeight: 600}}>Превышен лимит по тарифу</Typography>
          <Typography sx={{fontSize: 13}}>
            По тарифу доступно {declOfNum(maxRepricerItems || 0, ['товар', 'товара', 'товаров'])} товаров. Повысьте
            тариф, чтобы добавить больше товаров
          </Typography>
          <MButton
            onClick={() => navigate(getRoutePricing())}
            sx={{marginTop: '15px'}}
            size="small"
            variant="contained"
          >
            Повысить тариф
          </MButton>
        </div>
      );
    }
  }, [isRepricerLimited]);

  return (
    <SlideUpModal
      opened={isOpen}
      onClose={onClose}
      title={
        strategyId
          ? `Редактирование стратегии ${repricer.list.find((item) => item.id === Number(strategyId))?.name}`
          : 'Создайте стратегию'
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
        <StrategyContext.Provider
          value={{
            strategy: strategyData,
            productsOnStrategy,
            onSelectProduct,
            deleteProductIds,
            onChangeStrategy: (strategy) => setStrategyData(strategy),
            initialRepricerItems: initialRepricerItems,
            isRepricerLimited,
          }}
        >
          <EditStrategyStepper steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
          {currentStep === 0 && <AddProducts />}
          <InfiniteLoadContext.Provider
            value={{
              offset: pageParams.offset,
              loadMoreItems: loadMoreRepricerItems,
              isLoadingRepricerItems,
            }}
          >
            {currentStep === 1 && (
              <StrategyDetails
                collectionPriceUpdateFunctions={collectionPriceUpdateFunctions}
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                resetCollectPriceUpdateFunctions={resetCollectPriceUpdateFunctions}
              />
            )}
          </InfiniteLoadContext.Provider>
        </StrategyContext.Provider>
      </div>
    </SlideUpModal>
  );
});
