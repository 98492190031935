import {FC} from 'react';

import TableRow, {TableRowProps} from '@mui/material/TableRow/TableRow';

interface IMTableRow {
  children?: React.ReactNode;
}

type TMTableRowProps = IMTableRow & TableRowProps;

export const MTableRow: FC<TMTableRowProps> = ({children, ...props}) => {
  return <TableRow {...props}>{children}</TableRow>;
};
