import {FC} from 'react';

import TableHead, {TableHeadProps} from '@mui/material/TableHead/TableHead';

interface IMTableHead {
  children?: React.ReactNode;
}

type TMTableHead = IMTableHead & TableHeadProps;

export const MTableHead: FC<TMTableHead> = ({children, ...props}) => {
  return <TableHead {...props}>{children}</TableHead>;
};
