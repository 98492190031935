import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(({isClearable}: {isClearable: boolean}) => ({
  inputAdornment: {},
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
    },
    '&&.MuiInputBase-input': {
      paddingRight: isClearable ? 0 : '30px',
    },
  },
}));
