import {FC} from 'react';

import {MTypography} from '../typography/typography';

interface IPageTitleProps {
  children?: React.ReactNode;
}

export const PageTitle: FC<IPageTitleProps> = ({children, ...props}) => {
  return (
    <MTypography variant="h2" sx={{fontWeight: 700, fontSize: 34}} {...props}>
      {children}
    </MTypography>
  );
};
