import {FC, ReactNode} from 'react';

import Typography, {TypographyProps} from '@mui/material/Typography';

interface ITypographyProps {
  children?: ReactNode;
}

type TTypography = TypographyProps & ITypographyProps;

export const MTypography: FC<TTypography> = ({children, ...props}) => {
  return <Typography {...props}>{children}</Typography>;
};
