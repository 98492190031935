import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  productName: {
    maxWidth: 500,
    width: 'fit-content',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    fontSize: 14,
  },
  productSku: {
    display: 'inline-block',
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 11,
    color: COLORS_REDESIGN.DARK_GREY,
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  price: {
    whiteSpace: 'nowrap',
  },
  noImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 34,
    height: 45,
  },
  actionsCellContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  isUseItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
}));
