import {type FC} from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Step from '@mui/material/Step/Step';
import StepLabel from '@mui/material/StepLabel/StepLabel';
import Stepper from '@mui/material/Stepper/Stepper';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';

import {useEmotionWrapper} from './edit-strategy-stepper.s';

interface IEditStrategyStepperProps {
  steps: string[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export const EditStrategyStepper: FC<IEditStrategyStepperProps> = ({steps, currentStep, setCurrentStep}) => {
  const {classes} = useEmotionWrapper();

  return (
    <div className={classes.root}>
      <Stepper classes={{root: classes.rootStepper}} activeStep={currentStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <MTypography sx={{fontWeight: 700, fontSize: 18}}>{label}</MTypography>
            </StepLabel>
          </Step>
        ))}

        <div className={classes.prevStep}>
          {currentStep === 1 && (
            <MButton startIcon={<ArrowBackIcon />} variant="text" onClick={() => setCurrentStep(currentStep - 1)}>
              Предыдущий шаг
            </MButton>
          )}
        </div>

        <div className={classes.nextStep}>
          {currentStep !== 1 && (
            <MButton endIcon={<ArrowForwardIcon />} variant="text" onClick={() => setCurrentStep(currentStep + 1)}>
              Следующий шаг
            </MButton>
          )}
        </div>
      </Stepper>
    </div>
  );
};
