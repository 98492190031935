import {SUBSCRIPTION_DURATION} from '@src/shared/enums/subscription-duration';
import {TARIFFS_TYPES} from '@src/shared/enums/tariffs';

import {useStore} from '../stores';

export const useCurrentTariff = () => {
  const {mpAccounts, tariffs} = useStore();

  const currentTariff = tariffs.list.find((item) => item.id === mpAccounts.current?.tariff?.tariffId);
  const durationOnCountMonths = () => {
    if (!currentTariff) {
      return {
        currentTariff: {
          maxRepricerItems: 0,
        },
      };
    } else {
      if (mpAccounts.current?.tariff?.periodFrom && mpAccounts.current?.tariff?.periodTo) {
        const from = new Date(mpAccounts.current?.tariff?.periodFrom);
        const to = new Date(mpAccounts.current?.tariff?.periodTo);
        const diffTime = Math.abs(to.getTime() - from.getTime());
        const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
        if (diffMonths < 10) {
          return SUBSCRIPTION_DURATION.MONTH;
        } else {
          return SUBSCRIPTION_DURATION.YEAR;
        }
      }
    }
  };

  return {
    currentTariff: {
      ...currentTariff,
      id: mpAccounts.current?.tariff?.tariffId,
      isTrial: mpAccounts.current?.tariff?.isTrial,
      periodFrom: mpAccounts.current?.tariff?.periodFrom,
      periodTo: mpAccounts.current?.tariff?.periodTo,
      activeSubscription: mpAccounts.current?.activeSubscription,
      isLimited: mpAccounts.current?.tariff?.isLimited,
      subscriptionDuration: durationOnCountMonths(),
      nextPaymentDate: mpAccounts.current?.nextPaymentDate,
      hadPaidTariff: mpAccounts.current?.hadPaidTariff,
      analyticsDashboardUrl: mpAccounts.current?.analyticsDashboardUrl,
      maxRepricerItems: currentTariff?.maxRepricerItems,
      maxBidderItems: currentTariff?.maxBidderItems,
    },
    isFreeTariff: currentTariff?.type === TARIFFS_TYPES.START,
  };
};
