import React from 'react';
import {Instance, types} from 'mobx-state-tree';

import {connectReduxDevtools} from 'mst-middlewares';
//@ts-ignore
import remotedev from 'remotedev';

import {ProductGroupsStore} from './groups/groups';
import {mpAccountStore} from './market-place-account/market-place-account';
import {mpItemsStore} from './market-place-account-item/market-place-account-items';
import {RepricerStore} from './repricer/repricer';
import {RulesStore} from './rules/rules';
import {TariffsStore} from './tariffs/tarrifs';
import {UserStore} from './user/user';
import {UtilsStore} from './utils/utils';

export type RootStoreModel = Instance<typeof RootStore>;

export const RootStore = types.model('RootStore', {
  user: types.optional(UserStore, {}),
  mpAccounts: types.optional(mpAccountStore, {}),
  mpItems: types.optional(mpItemsStore, {}),
  rules: types.optional(RulesStore, {}),
  productGroups: types.optional(ProductGroupsStore, {}),
  repricer: types.optional(RepricerStore, {}),
  tariffs: types.optional(TariffsStore, {}),
  utils: types.optional(UtilsStore, {}),
});

const rootStore: RootStoreModel = RootStore.create({
  user: {},
  mpAccounts: {},
  mpItems: {},
  rules: {},
  productGroups: {},
  repricer: {},
  tariffs: {},
  utils: {},
});
if (process.env.NODE_ENV === 'development') connectReduxDevtools(remotedev, rootStore);

export const StoreContext = React.createContext<RootStoreModel>({} as RootStoreModel);

export const useStore = () => React.useContext(StoreContext);

export const StoreProvider = ({children}: {children: React.ReactNode}) => {
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
};
