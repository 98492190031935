import {FC} from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {FormControl, InputLabel} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import {SelectChangeEvent} from '@mui/material/Select';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MInput} from '@src/components/redesign/mui-components/common/input/input';
import {MSelect} from '@src/components/redesign/mui-components/common/select/select';
import {REPRICER_STRATEGY_SELECT_OPTIONS, TRepricerStrategyValues} from '@src/shared/enums/repricer-strategy';

import {TRepricerStrategiesFilterCriteria} from '../../../types/strategies';

import {useEmotionWrapper} from './strategies-filter.s';

const STATUSES_VALUES = [
  {
    label: 'Включено',
    value: true,
  },
  {
    label: 'Выключено',
    value: false,
  },
];

interface IStrategiesFilter {
  filterCriteria: TRepricerStrategiesFilterCriteria;
  onChangeFilterCriteria: (criteria: TRepricerStrategiesFilterCriteria) => void;
}

export const StrategiesFilter: FC<IStrategiesFilter> = ({onChangeFilterCriteria, filterCriteria}) => {
  const {classes} = useEmotionWrapper();

  const handleChangeStrategies = (event: SelectChangeEvent<unknown>) => {
    onChangeFilterCriteria({
      ...filterCriteria,
      type: event.target.value as TRepricerStrategyValues[],
    });
  };

  const handleChangeStatuses = (event: SelectChangeEvent<unknown>) => {
    onChangeFilterCriteria({
      ...filterCriteria,
      enabled: event.target.value as Array<'true' | 'false'>,
    });
  };

  const resetFilters = () => {
    onChangeFilterCriteria({
      searchItem: '',
      type: [],
      enabled: [],
    });
  };

  return (
    <div className={classes.root}>
      <MInput
        autoComplete="off"
        placeholder="Поиск товара в стратегии"
        sx={{width: 350}}
        onChange={(e) =>
          onChangeFilterCriteria({
            ...filterCriteria,
            searchItem: e.target.value,
          })
        }
        value={filterCriteria.searchItem}
        size="small"
        slotProps={{
          input: {
            endAdornment: <SearchIcon />,
          },
        }}
      />
      <FormControl>
        <InputLabel size="small" id="demo-multiple-name-label">
          Стратегия
        </InputLabel>
        <MSelect
          size="small"
          sx={{width: 250}}
          value={filterCriteria.type}
          onChange={handleChangeStrategies}
          multiple
          placeholder="Выберите стратегию"
          input={<OutlinedInput label="Стратегия" />}
        >
          {REPRICER_STRATEGY_SELECT_OPTIONS.map((s) => (
            <MenuItem key={s.label} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
        </MSelect>
      </FormControl>
      <FormControl>
        <InputLabel size="small">Статус</InputLabel>
        <MSelect
          size="small"
          sx={{width: 250}}
          value={filterCriteria.enabled}
          onChange={handleChangeStatuses}
          multiple
          placeholder="Статус"
          input={<OutlinedInput label="Статус" />}
        >
          {STATUSES_VALUES.map((s) => (
            <MenuItem key={s.label} value={s.value.toString()}>
              {s.label}
            </MenuItem>
          ))}
        </MSelect>
      </FormControl>
      {filterCriteria.searchItem?.length || filterCriteria.type?.length || filterCriteria.enabled?.length ? (
        <MButton onClick={resetFilters}>Сбросить фильтры</MButton>
      ) : null}
    </div>
  );
};
