import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {},
  tableHeader: {
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
  },
  tableHeaderRow: {
    th: {
      fontWeight: 600,
      '&:first-of-type': {
        paddingLeft: 50,
      },
      '&:last-child': {
        paddingRight: 50,
      },
    },
  },
  tableBody: {
    td: {
      '&:first-of-type': {
        paddingLeft: 50,
      },
      '&:last-child': {
        paddingRight: 50,
      },
    },
  },
}));
