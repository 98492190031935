import {Skeleton} from '@mui/material';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';

export const StrategiesTableLoader = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((_, index) => (
    <MTableRow key={index}>
      <MTableCell colSpan={3}>
        <Skeleton sx={{height: '38px', width: '100%'}} />
      </MTableCell>
    </MTableRow>
  ));
};
