import {type FC} from 'react';

import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {insertSeparatorToNumberOrString} from '@src/shared/utils/insert-separator-to-number-or-string';

interface IPriceBlockProps {
  price: number | null;
  description: string;
}

export const PriceBlock: FC<IPriceBlockProps> = ({price, description}) => {
  if (price === null) return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: 12,
        width: 243,
        height: 65,
        backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
      }}
    >
      <MTypography sx={{fontWeight: 500}}>{insertSeparatorToNumberOrString(price)} ₽</MTypography>
      <MTypography sx={{color: COLORS_REDESIGN.DARK_GREY, fontSize: 12}}>{description}</MTypography>
    </div>
  );
};
