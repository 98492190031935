export const PlaceIcon = ({size = '24', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <mask id="mask0_770_7632" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={color} />
      </mask>
      <g mask="url(#mask0_770_7632)">
        <path
          d="M6 12.667V11.3337H14V12.667H6ZM6 8.66699V7.33366H14V8.66699H6ZM6 4.66699V3.33366H14V4.66699H6ZM3.33333 5.33366C2.96667 5.33366 2.65278 5.2031 2.39167 4.94199C2.13056 4.68088 2 4.36699 2 4.00033C2 3.63366 2.13056 3.31977 2.39167 3.05866C2.65278 2.79755 2.96667 2.66699 3.33333 2.66699C3.7 2.66699 4.01389 2.79755 4.275 3.05866C4.53611 3.31977 4.66667 3.63366 4.66667 4.00033C4.66667 4.36699 4.53611 4.68088 4.275 4.94199C4.01389 5.2031 3.7 5.33366 3.33333 5.33366Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
