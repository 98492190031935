export enum APP_ROUTES {
  LOGIN = 'login',
  PRODUCTS = '/',
  PARTNER = 'partner',
  GROUPS = 'groups',
  YANDEX_AUTH = 'yandex-auth',
  RULES = 'rules',
  PRICING = 'pricing',
  ANALYTICS = 'analytics',
  SUMARRY_ANALYTICS = 'summary-analytics',
  ANALYTICS_DEV = 'analytics-dev',
  ANALYTICS_DEPRECATED = 'analytics-deprecated',
  SETTINGS = 'settings',
  // last
  NOT_FOUND = 'not_found',
}

export const getRouteLogin = () => '/login';
export const getRouteYandexAuth = () => '/yandex-auth';
export const getRouteProducts = () => '/';
export const getRouteGroups = () => '/groups';
export const getRouteRules = () => '/sales-boost';
export const getRouteRepricer = () => '/repricer';
export const getRoutePricing = () => '/pricing';
export const getRouteAnalytics = () => '/analytics';
export const getRouteSummaryAnalytics = () => '/summary-analytics';
export const getRouteAnalyticsDev = () => '/analytics-dev';
export const getRouteAnalyticsDeprecated = () => '/analytics-deprecated';
export const getRouteSettings = () => '/settings/*';
export const getRoutePartner = () => '/partner/:id';

export const AppRouteByPathPattern: Record<string, APP_ROUTES> = {
  [getRouteLogin()]: APP_ROUTES.LOGIN,
  [getRouteYandexAuth()]: APP_ROUTES.YANDEX_AUTH,
  [getRouteProducts()]: APP_ROUTES.PRODUCTS,
  [getRouteGroups()]: APP_ROUTES.GROUPS,
  [getRouteRules()]: APP_ROUTES.RULES,
  [getRoutePricing()]: APP_ROUTES.PRICING,
  [getRouteAnalytics()]: APP_ROUTES.ANALYTICS,
  [getRouteSummaryAnalytics()]: APP_ROUTES.SUMARRY_ANALYTICS,
  [getRouteAnalyticsDev()]: APP_ROUTES.ANALYTICS_DEV,
  [getRouteAnalyticsDeprecated()]: APP_ROUTES.ANALYTICS_DEPRECATED,
  [getRouteSettings()]: APP_ROUTES.SETTINGS,
  [getRoutePartner()]: APP_ROUTES.PARTNER,
};
