import {cast, flow, types} from 'mobx-state-tree';
import api from '../../shared/configs/axiosConfig';
import {AxiosResponse} from 'axios';

import {LIMIT_ITEMS_ON_PAGE} from '@shared/const/localStorage';
import {BIDDER_STRATEGY, TRecommendationStrategyValues} from '@shared/enums/bidder-strategy';
import {handlingConsoleRequestError} from '@shared/utils/handlingConsoleRequestError';
import {TProductOtherLink} from '@src/components/specific/product-links-tooltip/types';
import {sortFields} from './consts';
import {
  TGetAccountItemsRequest,
  TIndividualStrategiesSummary,
  TItemCostPriceHistory,
  TItemFeeConditionsRequest,
  TItemStatsRequest,
  TItemStatsResponse,
  TMpAccountItem,
  TMpItemsFilterItem,
  TMpItemShop,
  TSearchItem,
  TSortFiled,
  TUpdateFeeConditionsRequest,
  TUpdateItemCurrentFeePctRequest,
  TUpdateItemMarginInShopRequest,
  TUpdateItemMaxBidderFeePctRequest,
  TUpdateItemStrategyRequest,
} from './types';

import {BidderStrategyModel} from '../shared-models/bidder-strategy-model';
import {FeeConditionsModel} from '../shared-models/fee-conditions-model';
import {RecommendationStrategyModel} from '../shared-models/recommendation-strategy-model';

export const MpItemsFilterItem = types.model('mpItemsFilterItem', {
  id: types.identifierNumber,
  name: types.string,
});

export const MpItemsFilterShopsItem = types.model('MpItemsFilterShopsItem', {
  id: types.identifierNumber,
  marketShopId: types.number,
  placementType: types.maybeNull(types.string),
  name: types.string,
  lastUpdateItems: types.maybeNull(types.string),
  lastUpdateSales: types.maybeNull(types.string),
  processUpdate: types.maybeNull(types.boolean),
});

export const MpItemsSelectedFilters = types.model('MpItemsSelectedFilters', {
  vendors: types.array(MpItemsFilterItem),
  categories: types.array(MpItemsFilterItem),
  groups: types.array(MpItemsFilterItem),
  shops: types.array(MpItemsFilterItem),
  strategies: types.array(types.string),
  order: types.array(types.enumeration(sortFields.slice())),
  haveIndividualStrategy: types.boolean,
  search: types.string,
  isInStock: types.maybeNull(types.boolean),
});

export const MpAccountItemFilters = types.model('MpAccountItemFilters', {
  vendors: types.array(MpItemsFilterItem),
  categories: types.array(MpItemsFilterItem),
  shops: types.array(MpItemsFilterShopsItem),
  groups: types.array(MpItemsFilterItem),
  strategies: types.array(types.string),
  selected: types.optional(MpItemsSelectedFilters, {
    shops: [],
    vendors: [],
    categories: [],
    order: [],
    groups: [],
    strategies: [],
    haveIndividualStrategy: false,
    isInStock: null,
    search: '',
  }),
});

export const PageParams = types.model('PageParams', {
  offset: types.optional(types.number, 0),
  limit: types.optional(types.number, Number(localStorage.getItem(LIMIT_ITEMS_ON_PAGE)) || 20),
});

export const MpItemsRule = types.model('MpItemsRule', {
  id: types.identifierNumber,
  name: types.string,
  groupsIds: types.array(types.number),
  vendorsIds: types.array(types.number),
  categoriesIds: types.array(types.number),
  shopsIds: types.array(types.number),
  strategy: types.maybeNull(
    types.enumeration('Strategy', [
      BIDDER_STRATEGY.AI,
      BIDDER_STRATEGY.MANUAL,
      BIDDER_STRATEGY.OFF,
      BIDDER_STRATEGY.RECOMMENDED,
    ])
  ),
  priority: types.number,
  maxBidderFeePct: types.number,
});

export const ErrorOnShop = types.model('ErrorOnShop');

export const ItemFeeRecommendation = types.model('ItemFeeRecommendation', {
  highFeePct: types.maybeNull(types.number),
  mediumFeePct: types.maybeNull(types.number),
  lowFeePct: types.maybeNull(types.number),
});

export const MpItemShop = types.model('MpItemShop', {
  id: types.identifierNumber,
  price: types.number,
  shopId: types.number,
  hasPromos: types.boolean,
  isBidderEnabled: types.boolean,
  marketShopId: types.number,
  margin: types.maybeNull(types.number),
  shopName: types.string,
  storageCount: types.number,
  strategy: types.maybeNull(BidderStrategyModel),
  currentFeePct: types.maybeNull(types.union(types.number)),
  maxBidderFeePct: types.maybeNull(types.union(types.number)),
  minPrice: types.maybeNull(types.number),
  maxPrice: types.maybeNull(types.number),
  rule: types.maybeNull(MpItemsRule),
  recommendationStrategy: RecommendationStrategyModel,
});

export const ItemRecommendationsModel = types.model('ItemRecommendationsModel', {
  highFeePct: types.maybeNull(types.number),
  mediumFeePct: types.maybeNull(types.number),
  lowFeePct: types.maybeNull(types.number),
});

export const MpAccountItem = types.model('MpAccountItem', {
  id: types.identifierNumber,
  feeConditions: types.optional(types.array(FeeConditionsModel), []),
  shops: types.array(MpItemShop),
  name: types.string,
  picture: types.maybeNull(types.string),
  category: types.string,
  vendor: types.string,
  sku: types.string,
  price: types.number,
  marketProductId: types.maybeNull(types.number),
  marketSku: types.maybeNull(types.number),
  isLoadingFeeConditions: types.optional(types.boolean, false),
});

export const mpItemsStore = types
  .model('mpItemsStore', {
    items: types.optional(types.array(MpAccountItem), []),
    filters: types.optional(MpAccountItemFilters, {}),
    pageParams: types.optional(PageParams, {}),
    count: types.maybe(types.number),
    shopItemsCount: types.maybe(types.number),
    shopItemsWithFeesCount: types.maybe(types.number),
    isLoading: types.optional(types.boolean, false),
    isLoadingStats: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    getItemsByAccountId: flow(function* (params: TGetAccountItemsRequest) {
      const {marketPlaceAccountId, offset, limit, isHiddenLoading = false, isInStock} = params;

      try {
        isHiddenLoading ? (self.isLoading = false) : (self.isLoading = true);
        const filters = {
          vendorIds: params.vendorsIds || self.filters.selected.vendors.map((vendor) => vendor.id) || undefined,
          categoryIds:
            params.categoriesId || self.filters.selected.categories.map((category) => category.id) || undefined,
          groupIds: params.groupIds || self.filters.selected.groups.map((group) => group.id) || undefined,
          shopsIds: params.shopsIds || self.filters.selected.shops.map((shop) => shop.id) || undefined,
          strategies: params.strategies || self.filters.selected.strategies || undefined,
          search: params.search || self.filters.selected.search || undefined,
        };
        const itemsData = yield api.post(`/item/list`, {
          pagination: {
            limit: limit !== undefined ? limit : self.pageParams.limit,
            offset: offset !== undefined ? offset : self.pageParams.offset,
          },
          filters: {
            vendorIds: filters.vendorIds.length > 0 ? filters.vendorIds : undefined,
            categoryIds: filters.categoryIds.length > 0 ? filters.categoryIds : undefined,
            shopIds: filters.shopsIds.length > 0 ? filters.shopsIds : undefined,
            groupIds: filters.groupIds.length > 0 ? filters.groupIds : undefined,
            strategies: filters.strategies.length > 0 ? filters.strategies : undefined,
            haveIndividualStrategy: self.filters.selected.haveIndividualStrategy,
            isInStock: isInStock || self.filters.selected.isInStock,
            search: filters.search,
          },
          marketPlaceAccountId: marketPlaceAccountId,
          orders: params.order && params.order?.length > 0 ? params.order : self.filters.selected.order,
        });

        const {data} = itemsData;

        self.items = data.items.map((item: TMpAccountItem) => {
          const findItem = self.items.find((itemInStore) => itemInStore.id === item.id);

          return {
            ...item,
            id: item.id,
            name: item.name,
            feeConditions: findItem ? findItem.feeConditions : [],
            shops: item.shops.map((shop: TMpItemShop) => ({
              ...shop,
              price: shop.price / 100,
            })),
            picture: item.picture,
            category: item.category,
            vendor: item.vendor,
            sku: item.sku,
            price: item.price / 100,
          };
        });
        self.count = data.count;
        // self.pageParams.offset = data.offset;
        self.pageParams.limit = data.limit;
        self.isLoading = false;

        return self.items as TMpAccountItem[];
      } catch (e) {
        self.isLoading = false;
        self.items = cast([]);
        handlingConsoleRequestError(e);
      }
    }),
    updateItemMaxBidderFeePct: flow(function* ({itemId, maxBidderFeePct, shopId}: TUpdateItemMaxBidderFeePctRequest) {
      try {
        yield api.patch(`/shops/${shopId}/items/${itemId}/max_bidder_fee_pct`, {maxBidderFeePct});

        const findItemIndex = self.items.findIndex((item) => item.id === itemId);
        if (findItemIndex !== -1) {
          const findShopIndex = self.items[findItemIndex].shops.findIndex((shop) => shop.shopId === shopId);
          if (findShopIndex !== -1) {
            self.items[findItemIndex].shops[findShopIndex].maxBidderFeePct = maxBidderFeePct;
          }
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemCurrentFeePct: flow(function* ({itemId, currentFeePct, shopId}: TUpdateItemCurrentFeePctRequest) {
      try {
        if (currentFeePct >= 0 && currentFeePct <= 99.9) {
          yield api.patch(`/shops/${shopId}/items/${itemId}/current_fee_pct`, {currentFeePct});
          const findItemIndex = self.items.findIndex((item) => item.id === itemId);
          if (findItemIndex !== -1) {
            const findShopIndex = self.items[findItemIndex].shops.findIndex((shop) => shop.id === shopId);
            if (findShopIndex !== -1) {
              self.items[findItemIndex].shops[findShopIndex].currentFeePct = currentFeePct;
            }
          }
        } else {
          throw new Error('Введите число от 0 до 99');
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemStrategyOnShop: flow(function* ({itemId, strategy, shopId}: TUpdateItemStrategyRequest) {
      try {
        yield api.patch(`shops/${shopId}/items/${itemId}/strategy`, {strategy});
        const findItemIndexItem = self.items.findIndex((item) => item.id === itemId);

        if (findItemIndexItem !== -1) {
          const findShopIndex = self.items[findItemIndexItem].shops.findIndex((shop) => shop.id === shopId);
          if (findShopIndex !== -1) {
            self.items[findItemIndexItem].shops[findShopIndex].strategy = strategy;
          }
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    itemsReset: flow(function* (marketPlaceAccountId: number) {
      yield api.put(`/market_place_account/${marketPlaceAccountId}/items/reset`).then((value) => value);
    }),
    itemReset: flow(function* (itemId: number) {
      yield api.patch(`/item/${itemId}/reset`).then((value) => value);
    }),
    itemFind: flow(function* (marketPlaceAccountId: number, skus: string[]) {
      try {
        const data = yield api.post(`/item/find`, {
          marketPlaceAccountId,
          skus,
        });
        return data.data.map((item: TSearchItem) => ({
          ...item,
          itemId: item.id,
        }));
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getVendorsByAccountId: flow(function* (marketPlaceAccountId: number) {
      try {
        const data = yield api.get(`/market_place_account/${marketPlaceAccountId}/vendors`);
        self.filters.vendors = data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getCategoriesByAccountId: flow(function* (marketPlaceAccountId: number) {
      try {
        const data = yield api.get(`/market_place_account/${marketPlaceAccountId}/categories`);
        self.filters.categories = data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),

    getShopsByAccountId: flow(function* (marketPlaceAccountId: number) {
      try {
        const data = yield api.get(`/market_place_account/${marketPlaceAccountId}/shops`);
        self.filters.shops = data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getStrategiesByAccountId: flow(function* (marketPlaceAccountId: number) {
      try {
        const data = yield api.get(`/market_place_account/${marketPlaceAccountId}/strategies`);
        self.filters.strategies = data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    selectShopsFilter(shops: Array<TMpItemsFilterItem>) {
      self.filters.selected.shops = cast(shops);
    },
    selectStrategyFilter(strategies: Array<string>) {
      self.filters.selected.strategies = cast(strategies);
    },
    selectVendorFilter(vendors: Array<TMpItemsFilterItem>) {
      self.filters.selected.vendors = cast(vendors);
    },
    selectCategoryFilter(categories: Array<TMpItemsFilterItem>) {
      self.filters.selected.categories = cast(categories);
    },
    selectGroupFilter(groups: Array<TMpItemsFilterItem>) {
      self.filters.selected.groups = cast(groups);
    },
    selectSearchFilter(search: string) {
      self.filters.selected.search = search;
    },
    selectHaveIndividualStrategy(state: boolean) {
      self.filters.selected.haveIndividualStrategy = state;
    },
    selectIsInStockItems(state: boolean | null) {
      self.filters.selected.isInStock = state;
    },
    resetItemsFilter() {
      self.filters.selected.vendors = cast([]);
      self.filters.selected.shops = cast([]);
      self.filters.selected.categories = cast([]);
      self.filters.selected.search = cast('');
      self.filters.selected.groups = cast([]);
    },
    setItemsOrder(ordersArray: TSortFiled[]) {
      self.filters.selected.order = cast(ordersArray);
    },
    setItemsOnPage(limitItem: number) {
      self.pageParams.limit = limitItem;
    },
    setOffsetItems(offsetItem: number) {
      self.pageParams.offset = offsetItem;
    },
    updateItemsOnState: (payload: Map<number, {currentFeePct: number; shopId: number}>) => {
      const items = [...self.items].map((item) => {
        const newCurrentFeePct = payload.get(item.id);
        if (newCurrentFeePct) {
          const findShopIndex = item.shops.findIndex((shop) => shop.shopId === newCurrentFeePct.shopId);
          //change current fee pct on shop
          if (findShopIndex !== -1) {
            item.shops[findShopIndex].currentFeePct = newCurrentFeePct.currentFeePct;
            return item;
          } else {
            return item;
          }
        } else {
          return item;
        }
      });
      self.items = cast(items);
    },
    updateItemRecommendationStrategy: flow(function* (
      itemId: number,
      recommendationStrategy: TRecommendationStrategyValues
    ) {
      try {
        yield api.patch(`/item/${itemId}/recommendation_strategy`, {
          strategy: recommendationStrategy,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    createItemLink: flow(function* (itemId: number, linkData: Omit<TProductOtherLink, 'id'>) {
      try {
        yield api.post(`/item_link/create`, {itemId: itemId, ...linkData});
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    deleteItemLink: flow(function* (itemLinkId: number) {
      try {
        yield api.delete(`/item_link/${itemLinkId}`);
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemLink: flow(function* (link: TProductOtherLink) {
      try {
        yield api.patch(`/item_link/${link.id}`, {
          type: link.type,
          link: link.link,
          name: link.name,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateFeeConditions: flow(function* ({conditions, shopItemId}: TUpdateFeeConditionsRequest) {
      try {
        yield api.patch(`shop_items/${shopItemId}/fee_conditions`, {
          conditions,
        });
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemMarginInShop: flow(function* ({itemId, margin, shopId}: TUpdateItemMarginInShopRequest) {
      try {
        yield api.patch(`shops/${shopId}/items/${itemId}/margin`, {
          margin,
        });
        const findIndexItem = self.items.findIndex((item) => item.id === itemId);
        if (findIndexItem !== -1) {
          const findIndexShop = self.items[findIndexItem].shops.findIndex((shop) => shop.shopId === shopId);
          if (findIndexShop !== -1) {
            self.items[findIndexItem].shops[findIndexShop].margin = margin;
          }
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getItemsIndividualStrategiesSummary: flow(function* (marketPlaceAccountId: number) {
      try {
        const data: TIndividualStrategiesSummary = yield api
          .get(`market_place_account/${marketPlaceAccountId}/items/individual_strategies/summary`)
          .then((value) => value.data);
        return data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItemPrice: flow(function* (itemId: number, price: number) {
      try {
        yield api.patch(`item/${itemId}/price`, {price});

        const findIndex = self.items.findIndex((item) => item.id === itemId);
        if (findIndex !== -1) {
          self.items[findIndex].price = price;
        }
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getItemRecommendations: flow(function* (itemId: number) {
      try {
        const data = yield api.get(`item/${itemId}/recommendations`);
        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getItemFeeConditions: flow(function* ({shopItemId, productId}: TItemFeeConditionsRequest) {
      const findItemIndex = self.items.findIndex((item) => item.id === productId);
      if (findItemIndex !== -1) {
        self.items[findItemIndex].isLoadingFeeConditions = true;
      }
      try {
        const data = yield api.get(`shop_items/${shopItemId}/fee_conditions`);
        const findIndex = self.items.findIndex((item) => item.id === productId);
        if (findIndex !== -1) {
          self.items[findIndex].feeConditions = data.data;
        }
        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      } finally {
        self.items[findItemIndex].isLoadingFeeConditions = false;
      }
    }),
    getItemsStats: flow(function* ({
      marketPlaceAccountId,
      filters = {
        vendorsIds: undefined,
        categoriesId: undefined,
        groupIds: undefined,
        shopsIds: undefined,
        search: undefined,
        strategies: undefined,
        haveIndividualStrategy: false,
        isInStock: null,
      },
    }: TItemStatsRequest) {
      try {
        self.isLoadingStats = true;

        const f = {
          vendorIds: filters.vendorsIds || self.filters.selected.vendors.map((vendor) => vendor.id) || undefined,
          categoryIds:
            filters.categoriesId || self.filters.selected.categories.map((category) => category.id) || undefined,
          groupIds: filters.groupIds || self.filters.selected.groups.map((group) => group.id) || undefined,
          shopsIds: filters.shopsIds || self.filters.selected.shops.map((shop) => shop.id) || undefined,
          strategies: filters.strategies || self.filters.selected.strategies || undefined,
          search: filters.search || self.filters.selected.search || undefined,
        };

        const data = yield api.post<AxiosResponse<TItemStatsResponse>>(`item/list/stats`, {
          marketPlaceAccountId,
          filters: {
            vendorIds: f.vendorIds.length > 0 ? f.vendorIds : undefined,
            categoryIds: f.categoryIds.length > 0 ? f.categoryIds : undefined,
            groupIds: f.groupIds.length > 0 ? filters.groupIds : undefined,
            shopIds: f.shopsIds.length > 0 ? filters.shopsIds : undefined,
            strategies: f.strategies.length > 0 ? filters.strategies : undefined,
            haveIndividualStrategy: self.filters.selected.haveIndividualStrategy,
            isInStock: filters.isInStock || self.filters.selected.isInStock,
            search: filters.search,
          },
        });
        self.shopItemsCount = data.data.shopItemsCount;
        self.shopItemsWithFeesCount = data.data.shopItemsWithFeesCount;
      } catch (e) {
        handlingConsoleRequestError(e);
      } finally {
        self.isLoadingStats = false;
      }
    }),
    getItemLinks: flow(function* (itemId: number, marketPlaceAccountId: number) {
      try {
        const data = yield api.get(`item/${itemId}/links`, {params: {marketPlaceAccountId}});

        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getPromos: flow(function* (shopItemId: number) {
      try {
        const data = yield api.get(`shop_items/${shopItemId}/promos`);

        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    updateItem: flow(function* ({itemId, costPrice}: {itemId: number; costPrice: number}) {
      try {
        const data = yield api.patch(`item/${itemId}`, {costPrice});

        return data.data;
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
    getItemCostPriceHistory: flow(function* (itemId: number) {
      try {
        const data = yield api.get<TItemCostPriceHistory[]>(`item/${itemId}/cost_price/history`);

        return data.data.map((item: TItemCostPriceHistory) => ({
          ...item,
          costPrice: item.costPrice / 100,
        }));
      } catch (e) {
        handlingConsoleRequestError(e);
      }
    }),
  }));
