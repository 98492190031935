import {observer} from 'mobx-react-lite';

import {LimitedAccount} from '@src/components/specific/limited-account/limited-account';
import {PriceMonitoringNotify} from '@src/components/specific/price-monitoring-notify/price-monitoring-notify';
import {TariffNotification} from '@src/components/specific/tariff-notification';
import {TrialOfferNotification} from '@src/components/specific/trial-offer-notification/trial-offer-notification';

export const NotificationsManager = observer(() => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 1}}>
      <LimitedAccount />
      <TariffNotification />
      <PriceMonitoringNotify />
      <TrialOfferNotification />
    </div>
  );
});
