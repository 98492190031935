export enum REPRICER_STRATEGY {
  DEFAULT = 'DEFAULT',
  PLACE = 'PLACE',
}

export type TRepricerStrategyValues = (typeof REPRICER_STRATEGY)[keyof typeof REPRICER_STRATEGY];

export const REPRICER_STRATEGY_SELECT_OPTIONS = [
  {value: REPRICER_STRATEGY.DEFAULT, label: 'Лучшая цена'},
  {value: REPRICER_STRATEGY.PLACE, label: 'Быть на позиции'},
];

export const REPRICER_PLACE_OPTIONS = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
];
