import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Skeleton} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {declOfNum} from '@src/shared/utils/declOfNum';
import {useStore} from '@src/stores';

export const RepricerItemsStats = observer(() => {
  const {repricer, mpAccounts} = useStore();
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const itemsCountOnRepricer = useMemo(() => {
    let totalItemsOnRepricer = 0;

    repricer.summaryRepricerItemIds.forEach((value) => {
      totalItemsOnRepricer += value.itemIds.length;
    });

    return totalItemsOnRepricer;
  }, [JSON.stringify(repricer.summaryRepricerItemIds)]);

  const getTotalItemsCount = async () => {
    if (mpAccounts.current?.id) {
      setIsLoading(true);
      try {
        const res = await repricer.getItemsByAccountId({
          marketPlaceAccountId: mpAccounts.current?.id,
        });

        if (res) {
          setTotalItemsCount(res.count!);
        }
      } catch (e) {
        console.log('e', e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    mpAccounts.current?.id && getTotalItemsCount();
  }, [mpAccounts.current?.id]);

  if (isLoading) {
    return <Skeleton animation="wave" width="234px" height="24px" />;
  }

  return (
    <Typography sx={{fontWeight: 400, color: COLORS_REDESIGN.NIGHT_GREY}}>
      Добавлено {declOfNum(itemsCountOnRepricer || 0, ['товар', 'товара', 'товаров'])} из {totalItemsCount} шт.
    </Typography>
  );
});
