import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  paper: {
    width: 290,
    maxWidth: 430,
    padding: 30,
    borderRadius: 17,
  },
  itemTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  root: {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
  },
  itemsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  pricePctContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  strategyTypeBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    flexWrap: 'wrap',
  },
}));
