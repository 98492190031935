import {FC} from 'react';

import TableContainer, {TableContainerProps} from '@mui/material/TableContainer/TableContainer';

interface TMTableContainer {
  children: React.ReactNode;
}

type TMTableContainerProps = TMTableContainer & TableContainerProps;

export const MTableContainer: FC<TMTableContainerProps> = ({children, ...props}) => {
  return <TableContainer {...props}>{children}</TableContainer>;
};
