import {FC} from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import Select, {SelectProps} from '@mui/material/Select/Select';
import {IconButton} from '@src/components/common/buttons/icon-button';

import {useEmotionWrapper} from './select.s';

interface IMSelect {
  isClearable?: boolean;
  clearFunction?: () => void;
}

type MSelectProps = SelectProps & IMSelect;

export const MSelect: FC<MSelectProps> = ({isClearable = false, clearFunction, ...props}) => {
  const {classes} = useEmotionWrapper({isClearable});

  return (
    <Select
      classes={{select: classes.select}}
      endAdornment={
        props.endAdornment
          ? props.endAdornment
          : isClearable && clearFunction
            ? //@ts-ignore
              props.value.length > 0 && (
                <InputAdornment sx={{marginRight: '10px', marginLeft: 0, paddingRight: '0px'}} position="end">
                  <IconButton size="small" onClick={() => clearFunction()}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            : null
      }
      {...props}
    />
  );
};
