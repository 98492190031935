import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    padding: 12,
    borderRadius: 8,
    maxWidth: 800,
    textAlign: 'justify',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 20,
  },
}));
