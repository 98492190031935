import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    padding: 16,
    borderRadius: 12,
  },
  price: {
    fontWeight: 500,
    fontSize: 18,
    color: COLORS_REDESIGN.DARK_NIGHT_BLUE,
  },
  button: {
    width: 176,
  },
}));
