import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    width: 'max-content',
    gap: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 12px',
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    borderRadius: 7,
  },
}));
