import {FC, useContext, useRef} from 'react';

import {TableContainer, Typography} from '@mui/material';
import {Loader} from '@src/components/common/loader/loader';
import {MTable} from '@src/components/redesign/mui-components/common/table/table';
import {MTableBody} from '@src/components/redesign/mui-components/common/table/table-body';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableHead} from '@src/components/redesign/mui-components/common/table/table-head';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useInfiniteLoader} from '@src/hooks/useInfiniteLoader';
import {NEW_STRATEGY_ID, REPRICER_SEARCH_ITEMS_OFFSET} from '@src/pages/repricer/consts';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {declOfNum} from '@src/shared/utils/declOfNum';

import {InfiniteLoadContext} from '../../../../context/infinite-load';
import {useStrategyContext} from '../../../../hooks/useStrategyContext';
import {MaxPriceHeaderCell} from '../max-price-header-cell/max-price-header-cell';
import {MinPriceHeaderCell} from '../min-price-header-cell/min-price-header-cell';
import {ProductSettingsRow} from '../product-settings-row/product-settings-row';
import {RepricerItemsLoader} from '../repricer-items-loader/repricer-items-loader';

import {useEmotionWrapper} from './product-settings-table.s';

interface IProductSettingsTableProps {
  collectPriceUpdateFunctions: (type: 'minPrice' | 'maxPrice' | 'price', updateFunction: () => Promise<void>) => void;
  resetCollectPriceUpdateFunctions: (type: 'minPrice' | 'maxPrice' | 'price') => void;
}

export const ProductSettingsTable: FC<IProductSettingsTableProps> = ({
  collectPriceUpdateFunctions,
  resetCollectPriceUpdateFunctions,
}) => {
  const {classes} = useEmotionWrapper();
  const {productsOnStrategy, strategy} = useStrategyContext();
  const tableEl = useRef<HTMLDivElement | null>(null);

  const {offset, loadMoreItems, isLoadingRepricerItems} = useContext(InfiniteLoadContext);
  const {loading} = useInfiniteLoader({
    refEl: tableEl,
    loadMore: () => loadMoreItems(offset + REPRICER_SEARCH_ITEMS_OFFSET),
    offset,
  });

  return (
    <TableContainer classes={{root: classes.tableRoot}} ref={tableEl}>
      <MTable stickyHeader>
        <MTableHead>
          <MTableRow>
            <MTableCell />

            <MTableCell>
              <Typography sx={{fontWeight: 700}}>Товары</Typography>
              <MTypography sx={{fontSize: 12, color: COLORS_REDESIGN.NIGHT_GREY}}>
                В стратегии {declOfNum(productsOnStrategy.length, ['товар', 'товара', 'товаров'])}
              </MTypography>
            </MTableCell>

            <MTableCell align="center" />

            <MTableCell sx={{fontWeight: 700}} align="center">
              <Typography sx={{fontWeight: 700}}>Цена товара</Typography>
            </MTableCell>

            <MTableCell align="center">
              <MinPriceHeaderCell resetCollectPriceUpdateFunctions={resetCollectPriceUpdateFunctions} />
            </MTableCell>

            <MTableCell align="center">
              <MaxPriceHeaderCell resetCollectPriceUpdateFunctions={resetCollectPriceUpdateFunctions} />
            </MTableCell>

            <MTableCell sx={{fontWeight: 700}}>
              <Typography sx={{fontWeight: 700}}>Цена конкурентов</Typography>
            </MTableCell>
          </MTableRow>
        </MTableHead>
        <MTableBody>
          {isLoadingRepricerItems && strategy.id !== NEW_STRATEGY_ID ? (
            <RepricerItemsLoader />
          ) : productsOnStrategy.length === 0 ? (
            <MTableRow>
              <MTableCell align="center" colSpan={7}>
                Нет товаров
              </MTableCell>
            </MTableRow>
          ) : (
            productsOnStrategy.map((product) => (
              <ProductSettingsRow
                key={product.id}
                product={product}
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
              />
            ))
          )}
          {loading && (
            <MTableRow>
              <MTableCell align="center" colSpan={6}>
                <div style={{display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'center'}}>
                  <MTypography>Загрузка товаров...</MTypography>
                  <Loader />
                </div>
              </MTableCell>
            </MTableRow>
          )}
        </MTableBody>
      </MTable>
    </TableContainer>
  );
};
