import {FC, useState} from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {CircularProgress, Link} from '@mui/material';
import Box from '@mui/material/Box/Box';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {removeHttpPrefix} from '@src/shared/utils/remove-http-prefix';
import {TRepricerItemCompetitor} from '@src/stores/repricer/types';

import {useEmotionWrapper} from './competitor-item.s';

interface ICompetitorItemProps {
  competitor: TRepricerItemCompetitor;
  onClickDeleteCompetitors: (competitorId: number) => Promise<void>;
  onChangeEnabled: (competitor: TRepricerItemCompetitor) => Promise<void>;
  idx: number;
}

export const CompetitorItem: FC<ICompetitorItemProps> = ({
  competitor,
  onClickDeleteCompetitors,
  onChangeEnabled,
  idx,
}) => {
  const {classes} = useEmotionWrapper();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingChangeEnabled, setIsLoadingChangeEnabled] = useState(false);

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await onClickDeleteCompetitors(competitor.id);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleChangeEnabled = async () => {
    setIsLoadingChangeEnabled(true);
    try {
      await onChangeEnabled({...competitor, enabled: !competitor.enabled});
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingChangeEnabled(false);
    }
  };

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      sx={{
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: idx % 2 === 0 ? COLORS_REDESIGN.LIGHT_GREY : 'none',
        borderRadius: '8px',
        gap: '5px',
      }}
    >
      <div style={{display: 'flex', alignItems: 'center'}}>
        {isLoadingChangeEnabled ? (
          <div style={{width: 42, height: 42, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress size={20} />
          </div>
        ) : (
          <Checkbox onChange={handleChangeEnabled} disabled={isLoadingChangeEnabled} checked={competitor.enabled} />
        )}
        <div
          className={classes.checkBoxLabel}
          onClick={(e) => {
            e.stopPropagation();
            window.open(competitor.url, '_blank');
          }}
        >
          <Link>{removeHttpPrefix(competitor.url)}</Link>
        </div>
      </div>

      <MIconButton onClick={handleDelete} size="small">
        {isLoadingDelete ? <CircularProgress size={20} /> : <DeleteOutlineIcon />}
      </MIconButton>
    </Box>
  );
};
