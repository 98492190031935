import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'center',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
